<template>
  <div class="prop-layout">
    <slot></slot>
  </div>
</template>

<script>
  export default {
    components: {},
    props: {
      title: String,
    },
    methods: {},
  };
</script>

<style lang="scss">

  .prop-layout {
    margin-right: 15px;
    border-bottom: 1px dotted #ddd;

    .prop-row:last-child {
      border-bottom: none;
    }

    .prop-row {
      @apply bg-gray-100;
      display: grid;
      grid-template-columns: 200px 1fr;
      border-left: 1px solid #ddd;
      border-bottom: 1px dotted #ddd;
      border-right: 1px solid #ddd;
      align-items: center;

      &:hover {
        .label {
          @apply font-semibold;
        }
      }

      .label {

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 12px;
        color: #555;
        text-align: right;
        padding: 6px 6px 4px 4px;
      }

      .value {
        padding-bottom: 4px;
        padding-top: 6px;
        padding-left: 6px;
        padding-right: 6px;
        font-size: 12px;
        font-weight: 600;
        background: #fff;
        height: 100%;
      }
    }

    .prop-section {
      .prop-section-title {
        @apply bg-gray-200;
        padding-bottom: 5px;
        padding-top: 5px;
        padding-left: 4px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 12px;
        font-weight: bold;
        color: #555;
        border-left: 1px solid #ddd;
        border-right: 1px solid #ddd;
        border-top: 1px solid #ddd;
      }

      .prop-section-content {
        border-top: 1px solid #ddd;
        background: #eee;
      }
    }
  }
</style>
