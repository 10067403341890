<template>
  <div class="auto-prop-layout">
    <prop-layout>
      <prop-section :title="title">
        <prop-row v-for="prop in props" :key="prop[propName]" :label="prop.label">
          <div v-if="prop.link">
            <link-badge :title="propValue(prop)" :link="prop.link" :params="{id: data[propName]}"></link-badge>
          </div>
          <div v-else>
            <div class="prop-value">{{propValue(prop)}}</div>
          </div>
        </prop-row>
      </prop-section>
    </prop-layout>
  </div>
</template>

<script>
import numeral from 'numeral';

export default {
    props: {
      data: Object,
      props: Array,
      propName: String,
      propTypeName: String,
      title: {
        type: String,
        default: 'Properties',
      },
    },
    methods: {
      propValue(prop) {
        let val = this.data[prop[this.propName]];
        if (this.propTypeName && prop[this.propTypeName] === 'NUMBER') {
          val = numeral(val).format('0,0.00');
        }
        return val;
      },
    },
  };
</script>

<style scoped lang="scss">
</style>
