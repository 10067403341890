<template>
  <div class="form-section">
    <div class="form-section-title" v-if="title">
      {{title}}
    </div>
    <div class="form-section-content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
  export default {
    components: {},
    props: {
      title: String,
    },
    methods: {},
  };
</script>
