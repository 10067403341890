export default {
  created: function () {
    this.$http.interceptors.response.use((response) => response,
      (error) => {
        if (error.response.status === 403 && this.$router.currentRoute.path !== '/login') {
          this.$router.push('/logout');
        } else {
          throw error;
        }
      })
  },
  methods: {
    async $httpPost(url, data, customOptions) {
      const options = customOptions || { showProgress: true }

      if (options.showProgress) {
        this.$startLoading()
      }
      try {
        const resp = await this.$http.post(url, data || {})
        return resp.data
      } finally {
        if (options.showProgress) {
          this.$finishLoading()
        }
      }
    },
    async $httpPostWithFeedback(url, data, completeMsg) {
      try {
        const respData = await this.$httpPost(url, data)
        if (completeMsg) {
          this.$message.info(completeMsg)
        }
        return respData
      } catch (e) {
        this.$message.error('Failed')
        throw e
      }
    },
    async $httpPostForm(url, data, form) {
      try {
        const resp = await this.$http.post(url, data)
        return resp
      } catch (e) {
        console.log(e)
        if (e.response.status === 400 && e.response.data.errors) {
          const errors = e.response.data.errors
          form.errors = errors
        } else if (e.response.status === 500) {
          form.errors = [{ 'property': 'generic', 'errorCode': 'server-error' }]
        } else {
          throw e
        }
      }
    },
    $startLoading() {
      this.$root.$data.loadingProgress++;
    },
    $finishLoading() {
      this.$root.$data.loadingProgress--;
    },
    $httpPostInlineInputUpdate(url, form, item, property, callback, errorCallback) {
      this.$http.post(url, form)
        .then((resp) => {
          item[property] = 'success'
          if (callback) {
            callback(resp.data)
          }
          this.$forceUpdate()
          setTimeout(() => {
            item[property] = null
            this.$forceUpdate()
          }, 1500)
        })
        .catch((error) => {
          item[property] = 'error'
          this.$forceUpdate()
          if (errorCallback) {
            errorCallback(error.response.data)
          }
        })
    },
  }
}
