<template>
  <div class="form-row" :class="{'has-error' : hasError}">
    <div class="label" :class="{'ant-form-item-required': required}" v-html="label"></div>
    <div class="value">
      <slot></slot>
      <div class="ant-form-explain" v-for="err in errors" :key="err.errorCode">
        {{ errorMsg(err.errorCode) }}
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    components: {},
    props: {
      label: String,
      required: {
        type: Boolean,
        default: false
      },
      errors: {
        type: Array,
        default: () => {
          return []
        }
      },
      errorMessages: {
        type: Object,
        default: () => {
          return {}
        }
      }
    },
    computed: {
      hasError() {
        return this.errors.length > 0
      },
    },
    methods: {
      errorMsg(code) {
        const msg = this.errorMessages[code]
        return msg ? msg : code;
      }
    },
  };
</script>
