<template>
  <div id="app-base">
    <router-view></router-view>
    <loading-progress></loading-progress>
  </div>
</template>

<script>
import LoadingProgress from '@/components/common/LoadingProgress';

export default {
    name: 'app',
    history: true,
    injectModels: ['Auth'],
    components: {
      LoadingProgress,
    },
    data() {
      return {};
    },
    methods: {},
  };
</script>
