<template>
  <div class="action-section">
    <div class="p-2 truncate font-bold text-sm">
      {{title}}
    </div>
    <div class="flex flex-col items-start">
      <slot></slot>
    </div>
  </div>
</template>

<script>
  export default {
    components: {},
    props: {
      title: String,
    },
    methods: {},
  };
</script>
