import scrollIntoView from 'smooth-scroll-into-view-if-needed';

export default {
  methods: {
    scrollToElementById(id) {
      setTimeout(() => {
        const el = document.getElementById(id);
        if (el) {
          // el.scrollIntoView(true);
          scrollIntoView(el, {
            behavior: 'smooth',
            scrollMode: 'if-needed',
            block: 'center',
            inline: 'center',
          });
        }
      }, 250);
    },
  },
};
