<template>
  <div class="ant-table border">
    <div class="search-box">
      <div class="p-1" style="width: 200px">
        <a-input placeholder="Meklēt"
                 v-model="searchText"
                 clearable
                 :allowClear="true"
                 v-if="searchColumns"
        >
        </a-input>
      </div>
    </div>
    <div class="ant-table-body">
      <table :class="css.table">
        <thead class="ant-table-thead">
        <tr>
          <th v-if="hasRowSelectedListener" class="ant-table-selection">
            <input type="checkbox" @click="toggleSelectAll" v-model="selectedAllRows">
          </th>
          <th v-for="field in columns" :class="{'has-sorters': !!field.sorter}" @click="sortBy(field)">
            <span v-html="fieldLabel(field)"></span>
            <span v-if="field.sorter">
              <div title="Sort" class="sort-carets">
                <a-icon type="caret-up" :class="['sorter-up', sortingCssState(field, 'ASC')]"/>
                <a-icon type="caret-down" :class="['sorter-down', sortingCssState(field, 'DESC')]"/>
              </div>
            </span>
          </th>
          <th v-for="title in fixedLastColNames">
            {{ title }}
          </th>
        </tr>
        </thead>
        <tbody class="ant-table-tbody">
        <tr v-for="(item,index) in visibleDataRows" :key="index">
          <td v-if="hasRowSelectedListener" class="ant-table-selection">
            <input type="checkbox" @click="toggleSelect(item[rowKey], $event)" :checked="isRowSelected(item[rowKey])">
          </td>
          <slot name="record" v-bind:record="item">
            <td v-for="field in columns">
              {{ item[field.dataIndex] }}
            </td>
          </slot>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
export default {
  name: 'MDataTable',
  props: {
    columns: {
      type: Array
    },
    fixedLastColNames: [],
    dataSource: {
      type: Array,
      required: true
    },
    css: {
      type: Object,
      default: () => ({
        table: ''
      })
    },
    rowKey: null,
    searchColumns: null
  },
  data() {
    return {
      selectedRowKeys: [],
      selectedAllRows: false,
      sorter: null,
      searchText: null
    }
  },
  computed: {
    hasRowSelectedListener() {
      return this.rowKey && this.$listeners && this.$listeners.onRowsSelected
    },
    visibleDataRows() {
      if (!this.searchText || !this.searchColumns) {
        return this.dataSource
      }
      const search = this.searchText.toLowerCase()
      return this.dataSource.filter(row => {
        for (const col of this.searchColumns) {
          let val = row[col] ? row[col].toLowerCase() : null
          if (val) {
            return val.indexOf(search) >= 0
          }
        }
        return false;
      })
    }
  },
  watch: {
    dataSource: function () {
      const selection = []
      this.selectedRowKeys.forEach(key => {
        if (this.dataSource.find(it => it[this.rowKey] === key)) {
          selection.push(key)
        }
      })
      this.selectedRowKeys = selection
      this.selectedAllRows = false
      this.$emit('onRowsSelected', this.selectedRowKeys)
    },
  },
  methods: {
    toggleSelectAll() {
      if (!this.selectedAllRows) {
        this.selectedRowKeys = this.dataSource.map(it => it[this.rowKey])
      } else {
        this.selectedRowKeys = []
      }
      this.$emit('onRowsSelected', this.selectedRowKeys)
    },
    toggleSelect(rowId, $event) {
      const val = $event.target.checked
      const idx = this.selectedRowKeys.indexOf(rowId)
      if (val && idx === -1) {
        this.selectedRowKeys.push(rowId)
      } else if (!val && idx > -1) {
        this.selectedRowKeys.splice(idx, 1)
      }
      if (this.selectedAllRows && !val) {
        this.selectedAllRows = false
      }
      this.$emit('onRowsSelected', this.selectedRowKeys)
    },
    isRowSelected(rowId) {
      return this.selectedRowKeys.indexOf(rowId) > -1
    },
    sortBy(field) {
      if (!field.sorter) {
        return
      }
      const sorter = this.sorter
      if (sorter && sorter.fieldName === field.dataIndex) {
        if (sorter.direction === 'ASC') {
          this.sorter = null
        } else {
          this.sorter.direction = 'ASC'
        }
      } else {
        this.sorter = {
          fieldName: field.dataIndex,
          direction: 'DESC'
        }
      }
      this.$emit('sortChanged', this.sorter)
    },
    sortingCssState(field, direction) {
      const sorter = this.sorter
      if (sorter && sorter.fieldName === field.dataIndex) {
        return sorter.direction === direction ? 'on' : 'off'
      }
      return 'off'
    },
    fieldLabel(field) {
      return field.title
    }
  }
};
</script>

<style scoped lang="scss">

.ant-table-selection {
  width: 2rem;
  text-align: center;

  input {
    cursor: pointer;
  }
}

.has-sorters {
  cursor: pointer;
}

.has-sorters:hover {
  background: #f2f2f2;
}

.sort-carets {
  display: inline-block;
  margin-left: 3px;
}

.sorter-up, .sorter-down {
  height: .5em;
  line-height: .5em;
  transform: scale(.91666667) rotate(0deg);
  display: block;
  color: #bfbfbf;

}

.sorter-up.on, .sorter-down.on {
  color: #1890ff;
}

.search-box {
  background: #fafafa;
}

</style>
