import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/home',
      name: 'home',
      component: () => import('@/pages/Home'),
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: 'dashboard',
          name: 'dashboard',
          component: () => import('@/components/dashboard/Dashboard'),
          meta: {
            title: 'Ēku saraksts',
          },
        },
        {
          path: 'building-devices/:id',
          name: 'building-devices',
          component: () => import('@/components/device/Devices'),
          meta: {
            title: 'Building rooms',
          },
        },
        {
          path: 'device-charts/:id',
          name: 'device-charts',
          component: () => import('@/components/device/DeviceChartsPage'),
          meta: {
            title: 'Room charts',
          },
        },
        {
          path: 'measurements',
          name: 'measurements',
          component: () => import('@/components/help/Measurements'),
          meta: {
            title: 'Mērījumi',
          },
        },
        {
          path: 'recommendations',
          name: 'recommendations',
          component: () => import('@/components/help/Recommendations'),
          meta: {
            title: 'Ieteikumi',
          },
        }
      ]
    },
    { path: '*', redirect: '/home/dashboard' },
  ],
});

export default router;
