<template>
  <div class="h-full w-full overflow-hidden" style="display: flex; flex-direction: column;">
    <div class="items-center grid grid-cols-1fr-auto border-gray-300 border-b">
      <div class="px-4 py-4 font-bold text-lg bg-white text-gray-900">
        {{title}}
      </div>
      <div class="px-2">
        <slot name="actions">
        </slot>
      </div>
    </div>
    <div class="grid grid-cols-auto-1fr w-full h-full overflow-auto" style="flex: 1">
      <div :class="secondaryMenu ? 'px-2 py-4 border-r border-gray-300 bg-gray-100' : ''">
        <slot name="secondary-menu"></slot>
      </div>
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      title: String,
      secondaryMenu: Boolean,
    },
  };
</script>

<style scoped lang="scss">

</style>
