import Vue from 'vue';

import Axios from 'axios';

import '@/assets/style-01.css';
import 'ant-design-vue/dist/antd.css';
import '@/assets/style-02.css';
import '@/assets/style-03.scss';
import '@/assets/tooltip.css';
import 'vue-router-tab/dist/lib/vue-router-tab.css';

import VueNumerals from 'vue-numerals';
import VTooltip from 'v-tooltip';
import VueCookies from 'vue-cookies';
import vueDebounce from 'vue-debounce';
import RouterTab from 'vue-router-tab';
import VueGtm from '@gtm-support/vue2-gtm';

import Antd from 'ant-design-vue';

import App from '@/App.vue';
import GlobalCache from '@/models/GlobalCache';
import router from '@/router';

import httpMixin from '@/mixins/http-mixin';
import ScrollUtils from '@/mixins/scroll-utils';
import formUtils from '@/mixins/form';
import utils from '@/mixins/utils';

import MDataTable from '@/components/common/MDataTable';
import LinkBadge from '@/components/common/LinkBadge';
import Page from '@/components/layout/Page';
import FormLayout from '@/components/layout/FormLayout';
import FormSection from '@/components/layout/FormSection';
import FormRow from '@/components/layout/FormRow';
import PropLayout from '@/components/layout/PropLayout';
import PropSection from '@/components/layout/PropSection';
import PropRow from '@/components/layout/PropRow';
import AutoPropLayout from '@/components/layout/AutoPropLayout';
import ActionLayout from '@/components/layout/ActionLayout';
import ActionSection from '@/components/layout/ActionSection';

const data = {
  loadingProgress: 0,
};

Vue.prototype.$http = Axios;

Vue.use(Antd);
Vue.use(VueNumerals);
Vue.use(VTooltip, { defaultDelay: 500 });
Vue.use(RouterTab);
Vue.use(vueDebounce);
Vue.use(VueCookies);

Vue.use(VueGtm, {
  id: 'GTM-MW3HD33',
  vueRouter: router
})

Vue.mixin(ScrollUtils);
Vue.mixin(httpMixin);
Vue.mixin(formUtils);
Vue.mixin(utils);

Vue.component('prop-layout', PropLayout);
Vue.component('prop-section', PropSection);
Vue.component('prop-row', PropRow);
Vue.component('auto-prop-layout', AutoPropLayout);
Vue.component('m-data-table', MDataTable);
Vue.component('link-badge', LinkBadge);
Vue.component('page', Page);
Vue.component('form-layout', FormLayout);
Vue.component('form-section', FormSection);
Vue.component('form-row', FormRow);
Vue.component('action-layout', ActionLayout);
Vue.component('action-section', ActionSection);

//https://github.com/vuetifyjs/vuetify/issues/9999
const ignoreWarnMessage = 'The .native modifier for v-on is only valid on components but it was used on <div>.';
Vue.config.warnHandler = function (msg, vm, trace) {
  // `trace` is the component hierarchy trace
  if (msg === ignoreWarnMessage) {
    msg = null;
    vm = null;
    trace = null;
  }
}

new Vue({
  el: '#app',
  router,
  data,
  models: {
    GlobalCache,
  },
  render: h => h(App),
});
