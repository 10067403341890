<template>
  <div class="prop-row">
    <div class="label" v-html="label"></div>
    <div class="value">
      <slot>{{value}}</slot>
    </div>
  </div>
</template>

<script>
  export default {
    components: {},
    props: {
      label: String,
      value: Object,
    },
    methods: {},
  };
</script>
