export default {
  methods: {
    formatDateTime(value) {
      //createdAt: 2021-09-07T11:49:03.006362Z
      if (value) {
        return value.substr(0, 19).replace('T', ' ')
      }
    }
  }
}
