<template>
  <router-link :to="{ name: link, params: params}" class="link" @click.native="$event.stopImmediatePropagation()">
    {{title}}
  </router-link>
</template>

<script>
  export default {
    props: {
      title: {},
      link: String,
      params: Object,
    },
    methods: {
    }
  };
</script>

<style scoped lang="scss">
  .link {
    display: inline;
    color: #4183c4;
  }
</style>
