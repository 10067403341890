<template>
  <div class="form-layout">
    <div class="form-content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
  export default {
    components: {},
    props: {
      title: String,
    },
    methods: {},
  };
</script>

<style lang="scss">

  .form-layout {
    @apply mr-2 border-b border-t;

    .form-row:last-child {
      border-bottom: none;
    }

    .form-row {
      display: grid;
      grid-template-columns: 200px 1fr;
      align-items: center;

      @apply border-l border-r;

      .label {
        @apply font-semibold p-4 truncate text-sm text-right;
      }

      .value {
        @apply px-4 py-2;
      }
    }

    .form-section {
      .form-section-title {
        @apply bg-gray-200 p-2 truncate font-bold border text-sm;
        border-top-style: none;
      }

      .form-section-content {
        @apply bg-gray-100;
      }
    }
  }
</style>
