export default {
  history: true,
  name: "GlobalCache",
  data: {
    cache: {},

  },
  methods: {
    get(name) {
      return this.cache[name]
    },
    put(name, value) {
      this.cache[name] = value
    }
  }
};
