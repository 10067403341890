<template>
  <div class="prop-section">
    <div class="prop-section-title" v-if="title">
      <div class="grid grid-cols-1fr-auto items-center">
        <slot name="title">
          {{ title }}
        </slot>
      </div>
    </div>
    <div class="prop-section-content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    title: String,
  },
  methods: {},
};
</script>
