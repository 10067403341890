export default {
  methods: {
    formFieldErrors(property, errors) {
      return errors.filter(it => it.property === property)
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
  },
};
