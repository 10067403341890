<template>
  <div class="action-layout">
    <div class="action-content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
  export default {
    components: {},
    props: {
      title: String,
    },
    methods: {},
  };
</script>

<style lang="scss">
</style>
